.agenda-calendar {
  width: 100%;
}

.hourly-slots {
  display: flex;
  flex-wrap: wrap;
}

.slot {
  display: block;
  width: 120px;
  padding: 10px;
  margin: 5px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.slot.selected {
  background-color: #28a745;
  color: white;
}

.hourly-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.hourly-slots .slot {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #007bff;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hourly-slots .slot.selected {
  background-color: #007bff;
  color: #ffffff;
}

.hourly-slots .slot:hover {
  background-color: #0056b3;
  color: #ffffff;
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.container .row {
  margin-bottom: 20px;
  margin-top: 40px;
}