.appointments-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/USELL/lighttrail\ 3.jpeg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.appointments-hero h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.appointments-hero h3 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.agenda-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/USELL/lighttrail\ 2.jpeg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.agenda-hero h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.profile-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/USELL/lighttrail\ 4.jpeg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.profile-hero h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.veilingenpagina {
  padding: 3rem 2rem;
  background-color: #f9f9f9;
}

.veilingenpagina .row {
  justify-content: center;
}
